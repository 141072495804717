import {Component, OnInit} from '@angular/core';
import {SkillGroupFilter, SkillGroupModel} from '../../models/edu-admin/skillGroup.model';
import {Router} from '@angular/router';
import {AdminSkillGroupService} from '../../services/admin/admin-skill-group.service';
import {QuestionModel} from '../../models/questions/question.model';
import {CultureModel} from '../../models/localization/culture.model';
import {ErrorModel} from '../../models/shared/error.model';
import {ModalService} from '../../shared/modals/modal/modal.service';
import {EVOSkillGroupListSort} from '../../shared/list-views/evo-skill-groups-list-view/evo-skill-groups-list-view.component';

@Component({
  selector: 'app-school-skill-group',
  templateUrl: './admin-skill-group.component.html',
  styleUrls: ['./admin-skill-group.component.css']
})
export class AdminSkillGroupComponent implements OnInit {

  skillGroups: SkillGroupModel[] = [];
  skillGroupSort = new EVOSkillGroupListSort();
  skillGroupFilter = new SkillGroupFilter();
  hasMoreData = true;

  showCreateSkillGroup = false;

  skillGroup = new SkillGroupModel();
  CategoryTypes = QuestionModel.CategoryTypes;
  CultureModel = CultureModel;

  constructor(
    private skillGroupSv: AdminSkillGroupService,
    private router: Router,
    private modalSv: ModalService
  ) {}

  ngOnInit() {
    this.skillGroupFilter.limit = 20;
    this.skillGroupFilter.sortByName = undefined;
    this.skillGroupFilter.sortByOrder = -1;
    this.skillGroupSort.key = 'order';
    this.skillGroupSort.value = -1;
    this.fetchSkillGroups();
  }

  onSearchSkillGroups(searchString: string) {

    this.skillGroupFilter.skip = 0;
    this.skillGroupFilter.name = searchString;
    this.skillGroups = [];

    this.fetchSkillGroups();

  }

  fetchSkillGroups() {

    this.skillGroupFilter.skip = this.skillGroups.length;
    this.skillGroupSv.fetchSkillGroups(this.skillGroupFilter).subscribe((skillGroups) => {

      this.hasMoreData = this.skillGroupFilter.limit === skillGroups.length;
      this.skillGroups.addUniqueArrayElements(skillGroups);

    });
  }

  onSkillGroupClicked(skillGroup: SkillGroupModel) {
    this.router.navigate(['admin', 'skillGroups', skillGroup._id, 'detail', 'questions']);
  }

  onCreateClicked() {
    this.showCreateSkillGroup = true;
  }

  onSortChanged(sort: EVOSkillGroupListSort) {

    this.skillGroupFilter.skip = 0;
    this.skillGroupFilter.sortByName = undefined;
    this.skillGroupFilter.sortByOrder = undefined;

    if (sort.key === 'name') {
      this.skillGroupFilter.sortByName = sort.value;
    } else if (sort.key === 'order') {
      this.skillGroupFilter.sortByOrder = sort.value;
    }

    this.skillGroups = [];

    this.fetchSkillGroups();

  }

  onCreateSkillGroup() {

    this.skillGroupSv.createSkillGroup(this.skillGroup).subscribe((skill) => {

      this.modalSv.showAlertModal('Success', 'Skill group was successfully created');
      this.skillGroups = [];
      this.showCreateSkillGroup = false;
      this.skillGroup = new SkillGroupModel();

      this.fetchSkillGroups();

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }
}
