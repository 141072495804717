import {Component, OnInit} from '@angular/core';
import {EVOQuestionFilter, AdminQuestionService} from '../../services/admin/admin-question.service';
import {QuestionModel} from '../../models/questions/question.model';
import {UserModel} from '../../models/authentication/user.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MobileService} from 'src/app/mobile/mobile.service';

@Component({
  selector: 'app-evo-school-questions',
  templateUrl: './evo-admin-questions.component.html',
  styleUrls: ['./evo-admin-questions.component.css']
})
export class EvoAdminQuestionsComponent implements OnInit {

  /**
   * Possible values: grouped, difficulty
   */
  displayType = 'grouped';
  UserModel = UserModel;

  showSidebar: Boolean = false;

  categories: { isVisible: boolean, category: string }[] = [];
  questions: QuestionModel[] = [];

  // The question id we just created, updated or viewed
  // used to scroll the question into view and/or open categories / subcategories
  questionId: string = null;
  question: QuestionModel = null;
  questionFilter = new EVOQuestionFilter();

  hasMoreData = false;

  CategoryTypes = QuestionModel.CategoryTypes;
  SubcategoryTypes = QuestionModel.SubCategoryTypes;
  AnswerTypes = QuestionModel.AnswerTypes;
  RepresentationTypes = QuestionModel.RepresentationTypes;
  BooleanTypes = QuestionModel.BooleanTypes;


  constructor(private questionSv: AdminQuestionService,
              private route: ActivatedRoute,
              private router: Router,
              public mbSv: MobileService) {
  }

  ngOnInit() {

    this.mbSv.onInit();
    this.questionFilter.limit = 100;
    this.questionFilter.isArchived = false;
    this.questionFilter.isPublished = true;

    this.route.queryParams.subscribe((params: Params) => {

      if (params.displayType) {
        this.displayType = params.displayType;
      }

      if (params.questionId) {
        this.questionId = params.questionId;
      } else {
        this.questionId = null;
      }

    });

    if (UserModel.getAdminCountry()) {

      this.fetchData();

    }

  }

  numFilters() {

    const keys = Object.keys(this.questionFilter);

    let count = 0;
    for (const key of keys) {
      if (key !== 'skip' &&
        key !== 'limit' &&
        key !== 'sortByName' &&
        key !== 'sortByCategory' &&
        key !== 'sortBySubcategory') {

        if (this.questionFilter[key] !== undefined) {
          count += 1;
        }

      }
    }

    return count;

  }

  onSearchClicked(searchTerm: string) {

    if (searchTerm && searchTerm !== '') {
      this.questionFilter.name = searchTerm;
    } else {
      this.questionFilter.name = undefined;
    }

    this.questions = [];
    this.questionFilter.skip = 0;

    this.fetchData();

  }

  fetchData() {

    this.questionFilter.skip = this.questions.length;

    this.questionSv.fetchAdminQuestions(this.questionFilter).subscribe((questions) => {

      this.hasMoreData = this.questionFilter.limit === questions.length;

      this.questions.addUniqueArrayElements(questions);

    });

  }

  onFilterChanged(key: string, value: string) {

    if (value === 'none' || value === null) {
      this.questionFilter[key] = undefined;
    } else if (value === 'true') {
      this.questionFilter[key] = true;
    } else if (value === 'false') {
      this.questionFilter[key] = false;
    } else {
      this.questionFilter[key] = value;
    }

    this.questionFilter.skip = 0;

    this.questions = [];
    this.fetchData();

  }

  onClearFilter() {

    this.questionFilter.questionIds = undefined;
    this.questionFilter.isStarterQuestion = undefined;
    this.questionFilter.isPublished = undefined;
    this.questionFilter.isArchived = undefined;

    this.questionFilter.category = undefined;
    this.questionFilter.subCategory = undefined;
    this.questionFilter.answerType = undefined;
    this.questionFilter.representation = undefined;

    this.questions = [];
    this.questionFilter.skip = 0;

    this.showSidebar = false;

    this.fetchData();

  }

  getCurrentFilter(key: string) {

    if (this.questionFilter[key] === null || this.questionFilter[key] === undefined) {
      return 'none';
    }

    if (typeof this.questionFilter[key] === 'boolean') {
      if (this.questionFilter[key] === true) {
        return 'true';
      } else {
        return 'false';
      }
    }

    return this.questionFilter[key];
  }


  onQuestionClicked(q: QuestionModel) {

    if (q === null) {
      this.router.navigate(['admin', 'questions', 'create'], {queryParams: { displayType: this.displayType }});
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['admin', 'questions', q._id, 'edit'], { queryParams: { displayType: this.displayType }})
      );
      window.open(url, '_blank');
    }

  }

}
