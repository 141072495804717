import { Component, OnInit } from '@angular/core';
import {ModalService} from '../shared/modals/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CultureModel} from '../models/localization/culture.model';
import {CountryModel} from '../models/localization/country.model';
import {StringExtensionModel} from '../models/extensions/string-extension.model';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {


  constructor(public modalSv: ModalService,
              private router: Router,
              private route: ActivatedRoute,
              private tSv: TranslateService) {}

  ngOnInit() {

    if (this.route.firstChild) {

      this.route.firstChild.params.subscribe((params: Params) => {

        const culture = params.culture;
        const country = params.country;

        /**
         * If we have a country we can set the culture accordingly and
         * check if we have the appropriate server for the region
         **/
        if (country && CountryModel.list().includes(country)) {

          const paths = StringExtensionModel.pathsFromURL(this.router.url);

          CountryModel.onCountryOrCultureRedirection(this.router, paths, country, culture, this.tSv);

        } else {

          /**
           * If we have neither country or culture we should set
           * a default value for culture and country.
           */

          if (!CountryModel.getHomepageCountry()) {
            CountryModel.setHomepageCountry(CountryModel.GBR);
            this.tSv.use(CultureModel.enGB);
          }

          this.router.navigate(StringExtensionModel.getHomeURLPaths(['landing']));

        }


      });
    }

  }



}
