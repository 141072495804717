export {};

declare global {

  interface Array<T> {

    pushUnique(elem: T);
    addUniqueArrayElements(array: Array<T>);

  }

}

if (!Array.prototype.pushUnique) {

  Array.prototype.pushUnique = function<T>(elem: T) {

    let elemExists = false;
    for (const e of this) {
      if (e['_id'] === elem['_id']) {
        elemExists = true;
      }
    }

    if (elemExists === false) {
      this.push(elem);
    }

  };

}

if (!Array.prototype.addUniqueArrayElements) {
  Array.prototype.addUniqueArrayElements = function<T>(elem: T[]) {

    for (const e of elem) {
      this.pushUnique(e);
    }

  };
}
