export class DropdownModel {

  key: string;
  value: string;
  iconURL: string;
  bgColor: string;

  constructor(key: string = null, value: string = null, iconURL = null) {
    this.key = key;
    this.value = value;
    this.iconURL = iconURL;
  }

  static GenerateFromKey(key: string): DropdownModel {

    const obj = new DropdownModel();
    obj.key = key;
    obj.value = key;

    return obj;

  }

}
