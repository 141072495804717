import {Component, OnInit} from '@angular/core';
import {AdminObjectiveService} from '../../services/admin/admin-objective.service';
import {ObjectiveFilter, ObjectiveModel} from '../../models/edu-admin/objective.model';
import {Router} from '@angular/router';
import {CountryModel} from '../../models/localization/country.model';
import {EVOObjectiveListSort} from '../../shared/list-views/evo-objective-list-view/evo-objective-list-view.component';

@Component({
  selector: 'app-admin-objective',
  templateUrl: './admin-objective.component.html',
  styleUrls: ['./admin-objective.component.css']
})
export class AdminObjectiveComponent implements OnInit {

  objectives: ObjectiveModel[] = [];
  objectiveFilter = new ObjectiveFilter();
  hasMoreData = true;

  countryDropdownList = CountryModel.privateDropdownList(true);

  newObjective = new ObjectiveModel();
  showCreateObjective = false;

  constructor(private router: Router,
              private objectiveSv: AdminObjectiveService) {
  }

  ngOnInit() {

    this.objectiveFilter.isArchived = false;
    this.onFetchMoreObjectives();

  }

  searchFilteredObjectives(searchString: string, country: string) {

    this.objectiveFilter.skip = 0;
    this.objectiveFilter.name = searchString;
    this.objectiveFilter.country = country;
    this.objectiveFilter.sort = 1;
    this.hasMoreData = true;

    this.objectives = [];

    this.onFetchMoreObjectives();

  }

  onFetchMoreObjectives() {

    this.objectiveFilter.skip = this.objectives.length;
    this.objectiveSv.fetchObjectives(this.objectiveFilter).subscribe((objectives) => {

      this.hasMoreData = this.objectiveFilter.limit === objectives.length;
      this.objectives.addUniqueArrayElements(objectives);

    });
  }

  onObjectiveClicked(obj: ObjectiveModel) {

    if (obj) {
      this.router.navigate(['admin', 'objectives', obj._id, 'detail', 'skillGroups']);
    }

  }

  onSortChanged(sort: EVOObjectiveListSort) {

    this.objectiveFilter.sort = sort.value;
    this.objectiveFilter.skip = 0;
    this.objectives = [];
    this.onFetchMoreObjectives();

  }

  onViewArchivedObjectives() {
    this.router.navigate(['admin', 'objectivesArchived']);
  }

  onCreateObjective() {

    this.objectiveSv.createObjective(this.newObjective).subscribe((createdObjective) => {

      this.objectives = [];
      this.onFetchMoreObjectives();
      this.showCreateObjective = false;
      this.newObjective = new ObjectiveModel();

    });

  }

}
