import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RootComponent } from '../root/root.component';
import { ForgotPassComponent } from '../landing-page/authentication/forgot-pass/forgot-pass.component';
import { VerifyEmailComponent } from '../landing-page/authentication/verify-email/verify-email.component';
import { ResetPassComponent } from '../landing-page/authentication/reset-pass/reset-pass.component';
import { PlatformRootComponent } from '../platform/pages/platform-root/platform-root.component';
import { DashboardComponent } from '../platform/pages/dashboard/dashboard.component';
import { BooksComponent } from '../platform/pages/books/books/books.component';
import { EmailNotVerifiedComponent } from '../landing-page/authentication/email-not-verified/email-not-verified.component';
import { AnswersComponent } from '../platform/pages/answers/answers.component';
import { SigninSecondaryComponent } from '../landing-page/authentication/signin-secondary/signin-secondary.component';
import { SignupSecondaryComponent } from '../landing-page/authentication/signup-secondary/signup-secondary.component';
import { CreateStudentComponent } from '../landing-page/authentication/create-student/create-student.component';
import { SelectSubscriptionComponent } from '../platform/payment-process/select-subscription/select-subscription.component';
import { BillingInformationComponent } from '../platform/payment-process/billing-information/billing-information.component';
import { SelectBookComponent } from '../platform/payment-process/select-book/select-book.component';
import { AboutEvolytesGameComponent } from '../platform/payment-process/about-evolytes-game/about-evolytes-game.component';
import { AboutMonitorComponent } from '../platform/payment-process/about-monitor/about-monitor.component';
import { AboutBookComponent } from '../platform/payment-process/about-book/about-book.component';
import { BillingConfirmationComponent } from '../platform/payment-process/billing-confirmation/billing-confirmation.component';
import { TcComponent } from '../landing-page/authentication/tc/tc.component';
import { PrivacyPolicyComponent } from '../landing-page/authentication/privacy-policy/privacy-policy.component';
import { EvoAdminRootComponent } from '../admin/evo-admin-root/evo-admin-root.component';
import { AdminUsersComponent } from '../admin/admin-users/admin-users.component';
import { AdminUserEditComponent } from '../admin/admin-users/admin-user-edit/admin-user-edit.component';
import { EvoAdminQuestionsComponent } from '../admin/evo-admin-questions/evo-admin-questions.component';
import { EvoAdminQEditComponent } from '../admin/evo-admin-questions/evo-admin-q-edit/evo-admin-q-edit.component';
import { EvoAdminSchoolsComponent } from '../admin/evo-admin-schools/evo-admin-schools.component';
import { EvoAdminSchoolEditComponent } from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-edit.component';
import {
  EvoAdminSchoolUserEditComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-edit/evo-admin-school-user-edit.component';
import {
  EvoAdminSchoolStudentEditComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-students/evo-admin-school-student-edit/evo-admin-school-student-edit.component';
import {
  EvoAdminSchoolUploadUsersComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-upload-teachers/evo-admin-school-upload-users.component';
import {
  EvoAdminSchoolUploadStudentsComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-students/evo-admin-school-upload-students/evo-admin-school-upload-students.component';
import { AdminPricingComponent } from '../admin/admin-pricing/admin-pricing.component';
import { EvoLandChooseCountryComponent } from '../landing-page/localization/evo-land-choose-country/evo-land-choose-country.component';
import { AdminBooksComponent } from '../admin/admin-books/admin-books.component';
import { AdminBookEditComponent } from '../admin/admin-books/admin-book-edit/admin-book-edit.component';
import { AdminBookVersionEditComponent } from '../admin/admin-books/admin-book-version-edit/admin-book-version-edit.component';
import { AdminBookIntegrationEditComponent } from '../admin/admin-books/admin-book-integration-edit/admin-book-integration-edit.component';
import { PricingComponent } from '../landing-page/pages/pricing/pricing.component';
import { LandingComponent } from '../landing-page/pages/landing/landing.component';
import { SchoolsComponent } from '../landing-page/pages/schools/schools.component';
import { AboutUsComponent } from '../landing-page/pages/about-us/about-us.component';
import { ResearchComponent } from '../landing-page/pages/research/research.component';
import { FaqComponent } from '../landing-page/pages/faq/faq.component';
import { FeaturesComponent } from '../landing-page/pages/features/features.component';
import { ContactUsComponent } from '../landing-page/pages/contact-us/contact-us.component';
import { AdminObjectiveComponent } from '../admin/admin-objective/admin-objective.component';
import { AdminObjectiveEditComponent } from '../admin/admin-objective/admin-objective-edit/admin-objective-edit.component';
import { AdminSkillGroupComponent } from '../admin/admin-skill-group/admin-skill-group.component';
import { AdminSkillGroupEditComponent } from '../admin/admin-skill-group/admin-skill-group-edit/admin-skill-group-edit.component';
import { AdminStudentsComponent } from '../admin/admin-students/admin-students.component';
import { AdminStudentEditComponent } from '../admin/admin-students/admin-student-edit/admin-student-edit.component';
import { SchoolAdminRootComponent } from '../platform/school-admin/school-admin-root/school-admin-root.component';
import { SchoolAdminTeachersComponent } from '../platform/school-admin/school-admin-teachers/school-admin-teachers.component';
import { SchoolAdminStudentsComponent } from '../platform/school-admin/school-admin-students/school-admin-students.component';
import {
  SchoolAdminStudentsImportComponent
} from '../platform/school-admin/school-admin-students-import/school-admin-students-import.component';
import {
  TeacherInvitationSignupComponent
} from '../platform/invitation-flows/teacher-invitation-signup/teacher-invitation-signup.component';
import {
  SchoolAdminTeacherDetailComponent
} from '../platform/school-admin/school-admin-teachers/school-admin-teacher-detail/school-admin-teacher-detail.component';
import {
  SchoolAdminStudentDetailComponent
} from '../platform/school-admin/school-admin-students/school-admin-student-detail/school-admin-student-detail.component';
import { SchoolAdminGroupsComponent } from '../platform/school-admin/school-admin-groups/school-admin-groups.component';
import {
  SchoolAdminGroupDetailComponent
} from '../platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail.component';
import { GameComponent } from '../landing-page/pages/features/game/game.component';
import { BookComponent } from '../landing-page/pages/features/book/book.component';
import { MonitorComponent } from '../landing-page/pages/features/monitor/monitor.component';
// tslint:disable-next-line:max-line-length
import {SchoolAdminTeachersInvitationsComponent} from '../platform/school-admin/school-admin-teachers/school-admin-teachers-invitations/school-admin-teachers-invitations.component';
// tslint:disable-next-line:max-line-length
import {SchoolAdminTeachersInvitationImportComponent} from '../platform/school-admin/school-admin-teachers/school-admin-teachers-invitation-import/school-admin-teachers-invitation-import.component';
// tslint:disable-next-line:max-line-length
import {EvoAdminSchoolUploadUsersInvitationsComponent} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-upload-teachers-invitations/evo-admin-school-upload-users-invitations.component';
// tslint:disable-next-line:max-line-length
import { EvoAdminSchoolUsersInvitationsComponent } from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-invitations/evo-admin-school-users-invitations.component';
import {BookDetailComponent} from '../platform/pages/books/book-detail/book-detail.component';
import {SelectStudentComponent} from '../platform/selection/select-student/select-student.component';
import { NewSettingsPageComponent } from '../platform/pages/settings/student-settings-page/new-settings-page.component';


import { SchoolAdminLicenseComponent } from '../platform/school-admin/school-admin-license/school-admin-license.component';
import {AccountsSettingsComponent} from '../platform/pages/settings/shared/accounts-settings/accounts-settings.component';
import {AuthSettingsComponent} from '../platform/pages/settings/shared/auth-settings/auth-settings.component';
import {
  SchoolAdminGroupDetailStudentsComponent
} from '../platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-students/school-admin-group-detail-students.component';
import {
  SchoolAdminGroupDetailTeachersComponent
} from '../platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-teachers/school-admin-group-detail-teachers.component';
import {
  SchoolAdminGroupDetailSettingsComponent
} from '../platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-settings/school-admin-group-detail-settings.component';
import {
  EvoAdminSchoolStudentsComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-students/evo-admin-school-students.component';
import {
  EvoAdminSchoolTeachersComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers.component';
import {
  EvoAdminSchoolLicensesComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-licenses/evo-admin-school-licenses.component';
import {
  EvoAdminSchoolSettingsComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-settings/evo-admin-school-settings.component';
import {
  EvoAdminSchoolTeachersEditStudentsComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-edit/evo-admin-school-teachers-edit-students/evo-admin-school-teachers-edit-students.component';
import {
  EvoAdminSchoolTeachersEditGroupsComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-edit/evo-admin-school-teachers-edit-groups/evo-admin-school-teachers-edit-groups.component';
import {
  EvoAdminSchoolTeachersEditSettingsComponent
} from '../admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-edit/evo-admin-school-teachers-edit-settings/evo-admin-school-teachers-edit-settings.component';
import {
  AdminSkillGroupQuestionsComponent
} from '../admin/admin-skill-group/admin-skill-group-edit/admin-skill-group-questions/admin-skill-group-questions.component';
import {
  AdminSkillGroupSettingsComponent
} from '../admin/admin-skill-group/admin-skill-group-edit/admin-skill-group-settings/admin-skill-group-settings.component';
import {TeacherLinkInvitationComponent} from '../platform/invitation-flows/teacher-link-invitation/teacher-link-invitation.component';
import {SelectSchoolComponent} from '../platform/selection/select-school/select-school.component';
import {AdminPlacementTestComponent} from '../admin/admin-placement-test/admin-placement-test.component';
import {AdminPlacementTestEditComponent} from '../admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-edit.component';
import {
  AdminPlacementTestSettingsComponent
} from '../admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-settings/admin-placement-test-settings.component';
import {
  AdminPlacementTestStructureComponent
} from '../admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-structure/admin-placement-test-structure.component';
import {
  AdminPlacementTestRequisiteSkillsComponent
} from '../admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-requisite-skills/admin-placement-test-requisite-skills.component';
import {
  AdminObjectiveSettingsComponent
} from '../admin/admin-objective/admin-objective-edit/admin-objective-settings/admin-objective-settings.component';
import {
  AdminObjectiveSkillGroupsComponent
} from '../admin/admin-objective/admin-objective-edit/admin-objective-skill-groups/admin-objective-skill-groups.component';
import {
  AdminObjectiveSkillsComponent
} from '../admin/admin-objective/admin-objective-edit/admin-objective-skills/admin-objective-skills.component';
import {
  SchoolAdminGroupAddExistingStudentComponent
} from '../platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-students/school-admin-group-add-existing-student/school-admin-group-add-existing-student.component';
import {
  SchoolAdminGroupAddNewStudentComponent
} from '../platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-students/school-admin-group-add-new-student/school-admin-group-add-new-student.component';
import {AdminObjectiveArchivedComponent} from '../admin/admin-objective/admin-objective-archived/admin-objective-archived.component';
import {
  AdminPlacementTestArchivedComponent
} from '../admin/admin-placement-test/admin-placement-test-archived/admin-placement-test-archived.component';
import {AdminUserStudentsComponent} from '../admin/admin-users/admin-user-edit/admin-user-students/admin-user-students.component';
import {AdminUserSettingsComponent} from '../admin/admin-users/admin-user-edit/admin-user-settings/admin-user-settings.component';
import {GroupRootComponent} from '../platform/groups/group-root/group-root.component';
import {GroupDashboardComponent} from '../platform/groups/group-dashboard/group-dashboard.component';
import {AppLinkComponent} from '../landing-page/deep-linking/app-link/app-link.component';
import {GroupStudentsComponent} from '../platform/groups/group-students/group-students.component';
import {GroupSkillsComponent} from '../platform/groups/group-skills/group-skills.component';
import {SchoolAdminSettingsComponent} from '../platform/school-admin/school-admin-settings/school-admin-settings.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'selectCountry', pathMatch: 'full' },
  { path: 'selectCountry', component: EvoLandChooseCountryComponent },
  { path: 'home', component: RootComponent, children: [
      { path: ':culture/landing', component: LandingComponent },
      { path: ':country/:culture/landing', component: LandingComponent },
      { path: ':culture/signin', component: SigninSecondaryComponent },
      { path: ':country/:culture/signin', component: SigninSecondaryComponent },
      { path: ':culture/signup', component: SignupSecondaryComponent },
      { path: ':country/:culture/signup', component: SignupSecondaryComponent },
      { path: ':culture/forgotpass', component: ForgotPassComponent },
      { path: ':country/:culture/forgotpass', component: ForgotPassComponent },
      { path: ':culture/tc', component: TcComponent },
      { path: ':country/:culture/tc', component: TcComponent },
      { path: ':culture/privacy', component: PrivacyPolicyComponent },
      { path: ':country/:culture/privacy', component: PrivacyPolicyComponent },
      { path: ':culture/verifyEmail', component: EmailNotVerifiedComponent },
      { path: ':country/:culture/verifyEmail', component: EmailNotVerifiedComponent },
      { path: ':culture/verifyEmail/:token', component: VerifyEmailComponent },
      { path: ':country/:culture/verifyEmail/:token', component: VerifyEmailComponent },
      { path: ':culture/resetpassword/:token', component: ResetPassComponent },
      { path: ':country/:culture/resetpassword/:token', component: ResetPassComponent },
      { path: ':culture/pricing', component: PricingComponent },
      { path: ':country/:culture/pricing', component: PricingComponent },
      { path: ':culture/schools', component: SchoolsComponent },
      { path: ':country/:culture/schools', component: SchoolsComponent },
      { path: ':culture/aboutUs', component: AboutUsComponent },
      { path: ':country/:culture/aboutUs', component: AboutUsComponent },
      { path: ':culture/research', component: ResearchComponent },
      { path: ':country/:culture/research', component: ResearchComponent },
      { path: ':culture/faq', component: FaqComponent },
      { path: ':country/:culture/faq', component: FaqComponent },
      { path: ':culture/contactUs', component: ContactUsComponent },
      { path: ':country/:culture/contactUs', component: ContactUsComponent },
      { path: ':culture/features', component: FeaturesComponent, children: [
          { path: 'game', component: GameComponent },
          { path: 'books', component: BookComponent },
          { path: 'monitor', component: MonitorComponent }
        ]
      },
      { path: ':country/:culture/features', component: FeaturesComponent, children: [
          { path: 'game', component: GameComponent },
          { path: 'books', component: BookComponent },
          { path: 'monitor', component: MonitorComponent }
        ]
      }
    ]
  },
  { path: 'appLink', component: AppLinkComponent },
  { path: 'inviteTeacher/:token', component: TeacherInvitationSignupComponent },
  { path: 'inviteTeacherV2/:token', component: TeacherInvitationSignupComponent },
  { path: 'linkInvite/:linkId', component: TeacherLinkInvitationComponent },
  { path: 'selectstudent', component: SelectStudentComponent },
  { path: 'createstudent', component: CreateStudentComponent },
  { path: 'selectsub', component: SelectSubscriptionComponent },
  { path: 'selectbook', component: SelectBookComponent },
  { path: 'confirmpurch', component: BillingInformationComponent },
  { path: 'bookintro', component: AboutBookComponent },
  { path: 'gameintro', component: AboutEvolytesGameComponent },
  { path: 'monitorintro', component: AboutMonitorComponent },
  { path: 'thankpurchase', component: BillingConfirmationComponent },
  { path: 'selectschool', component: SelectSchoolComponent },
  {
    path: 'platform', component: PlatformRootComponent, children: [
      { path: 'dash', component: DashboardComponent },
      { path: 'books', component: BooksComponent },
      { path: 'books/:bookVersion', component: BookDetailComponent },
      { path: 'answers', component: AnswersComponent },
      { path: 'settings', component: NewSettingsPageComponent, children: [
          { path: 'account', component: AccountsSettingsComponent },
          { path: 'auth', component: AuthSettingsComponent }
        ]
      }
    ]
  },
  {
    path: 'groupOverview/:groupId', component: GroupRootComponent, children: [
      { path: 'dash', component: GroupDashboardComponent },
      { path: 'students', component: GroupStudentsComponent },
      { path: 'skills', component: GroupSkillsComponent }
    ]
  },
  {
    path: 'schooladmin', component: SchoolAdminRootComponent, children: [
      { path: 'teachers', component: SchoolAdminTeachersComponent },
      { path: 'teachers/:teacherId', component: SchoolAdminTeacherDetailComponent },
      { path: 'students', component: SchoolAdminStudentsComponent },
      { path: 'students/:studentId', component: SchoolAdminStudentDetailComponent },
      { path: 'importStudents', component: SchoolAdminStudentsImportComponent },
      { path: 'groups', component: SchoolAdminGroupsComponent },
      { path: 'groups/:groupId', component: SchoolAdminGroupDetailComponent, children: [
          { path: 'students', component: SchoolAdminGroupDetailStudentsComponent },
          { path: 'addExistingStudents', component: SchoolAdminGroupAddExistingStudentComponent },
          { path: 'addNewStudents', component: SchoolAdminGroupAddNewStudentComponent },
          { path: 'teachers', component: SchoolAdminGroupDetailTeachersComponent },
          { path: 'settings', component: SchoolAdminGroupDetailSettingsComponent }
        ]
      },
      { path: 'invitations', component: SchoolAdminTeachersInvitationsComponent },
      { path: 'importInvitations', component: SchoolAdminTeachersInvitationImportComponent },
      { path: 'licenses', component: SchoolAdminLicenseComponent },
      { path: 'settings', component: SchoolAdminSettingsComponent }
    ]
  },
  {
    path: 'admin', component: EvoAdminRootComponent, children: [
      { path: 'users', component: AdminUsersComponent },
      { path: 'users/:userId/detail', component: AdminUserEditComponent, children: [
          { path: 'students', component: AdminUserStudentsComponent },
          { path: 'settings', component: AdminUserSettingsComponent }
        ]
      },
      { path: 'students', component: AdminStudentsComponent },
      { path: 'students/:studentId/edit', component: AdminStudentEditComponent },
      { path: 'users/:userId/edit', component: AdminUserEditComponent },
      { path: 'questions', component: EvoAdminQuestionsComponent },
      { path: 'questions/:questionId/edit', component: EvoAdminQEditComponent },
      { path: 'skillGroups', component: AdminSkillGroupComponent },
      { path: 'skillGroups/:skillGroupId/detail', component: AdminSkillGroupEditComponent, children: [
          { path: 'questions', component: AdminSkillGroupQuestionsComponent },
          { path: 'settings', component: AdminSkillGroupSettingsComponent }
        ]
      },
      { path: 'skillGroups/:skillGroupId/edit', component: AdminSkillGroupEditComponent },
      { path: 'questions/create', component: EvoAdminQEditComponent },
      { path: 'schools', component: EvoAdminSchoolsComponent },
      { path: 'schools/:schoolId/detail', component: EvoAdminSchoolEditComponent, children: [
          { path: 'students', component: EvoAdminSchoolStudentsComponent },
          { path: 'teachers', component: EvoAdminSchoolTeachersComponent },
          { path: 'licenses', component: EvoAdminSchoolLicensesComponent },
          { path: 'settings', component: EvoAdminSchoolSettingsComponent }
        ]
      },
      { path: 'schools/:schoolId/users/:userId', component: EvoAdminSchoolUserEditComponent, children: [
          { path: 'students', component: EvoAdminSchoolTeachersEditStudentsComponent },
          { path: 'groups', component: EvoAdminSchoolTeachersEditGroupsComponent },
          { path: 'settings', component: EvoAdminSchoolTeachersEditSettingsComponent }
        ]
      },
      { path: 'schools/create', component: EvoAdminSchoolEditComponent },
      { path: 'schools/:schoolId/users/create', component: EvoAdminSchoolUserEditComponent },
      { path: 'schools/:schoolId/users/import', component: EvoAdminSchoolUploadUsersComponent },
      { path: 'schools/:schoolId/users/:userId/edit', component: EvoAdminSchoolUserEditComponent },
      { path: 'schools/:schoolId/invitations', component: EvoAdminSchoolUsersInvitationsComponent },
      { path: 'schools/:schoolId/invitations/import', component: EvoAdminSchoolUploadUsersInvitationsComponent },
      { path: 'schools/:schoolId/students/create', component: EvoAdminSchoolStudentEditComponent },
      { path: 'schools/:schoolId/students/import', component: EvoAdminSchoolUploadStudentsComponent },
      { path: 'schools/:schoolId/students/:studentId/edit', component: EvoAdminSchoolStudentEditComponent },
      { path: 'pricing', component: AdminPricingComponent },
      { path: 'books', component: AdminBooksComponent },
      { path: 'books/create', component: AdminBookEditComponent },
      { path: 'books/:bookId/edit', component: AdminBookEditComponent },
      { path: 'books/:bookId/versions/create', component: AdminBookVersionEditComponent },
      { path: 'books/:bookId/versions/:versionId/edit', component: AdminBookVersionEditComponent },
      { path: 'books/:bookId/versions/:versionId/integrations/create', component: AdminBookIntegrationEditComponent },
      { path: 'books/:bookId/versions/:versionId/integrations/:integrationId/edit', component: AdminBookIntegrationEditComponent },
      { path: 'objectives', component: AdminObjectiveComponent },
      { path: 'objectivesArchived', component: AdminObjectiveArchivedComponent },
      { path: 'objectives/:objectiveId/detail', component: AdminObjectiveEditComponent, children: [
          { path: 'settings', component: AdminObjectiveSettingsComponent },
          { path: 'skillGroups', component: AdminObjectiveSkillGroupsComponent },
          { path: 'skills', component: AdminObjectiveSkillsComponent }
        ]
      },
      { path: 'placementTests', component: AdminPlacementTestComponent },
      { path: 'placementTestsArchived', component: AdminPlacementTestArchivedComponent },
      { path: 'placementTests/:testId/detail', component: AdminPlacementTestEditComponent, children: [
          { path: 'structure', component: AdminPlacementTestStructureComponent },
          { path: 'preRequisite', component: AdminPlacementTestRequisiteSkillsComponent },
          { path: 'settings', component: AdminPlacementTestSettingsComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'top', // Add options right here,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
