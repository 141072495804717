import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {StudentModel} from '../../../models/authentication/student.model';
import {SchoolAdminStudentService} from '../../../services/school/school-admin-student.service';
import {SchoolModel} from '../../../models/school/school.model';
import {ModalService} from '../../../shared/modals/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {EVOStudentListSort} from '../../../shared/list-views/evo-student-list-view/evo-student-list-view.component';
import {StudentFilterModel} from '../../../models/filters/student-filter.model';
import {
  EvoSchoolAdminCreateStudentModalService
} from '../../../shared/modals/evo-school-admin-create-student-modal/evo-school-admin-create-student-modal.service';


@Component({
  selector: 'app-school-school-students',
  templateUrl: './school-admin-students.component.html',
  styleUrls: ['./school-admin-students.component.css']
})
export class SchoolAdminStudentsComponent implements OnInit {

  showCreateStudent = false;
  showDeleteStudent = false;
  students: StudentModel[] = [];

  showStudentFilter = false;
  hasMoreStudents = true;
  filter = new StudentFilterModel();

  student = new StudentModel();

  fromAge: number;
  toAge: number;


  constructor(private router: Router,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private createStudentModalSv: EvoSchoolAdminCreateStudentModalService,
              private studentSv: SchoolAdminStudentService) { }

  ngOnInit() {

    this.fetchStudents();

  }


  onStudentsDeleted() {

    this.filter.skip = 0;
    this.showDeleteStudent = false;
    this.fetchStudents();

  }

  fetchStudents() {

    this.filter.skip = this.students.length;
    this.studentSv.fetchStudents(this.filter).subscribe((students) => {

      this.hasMoreStudents = students.length === this.filter.limit;

      this.students.addUniqueArrayElements(students);

    });

  }

  searchFilteredStudents(searchString: string) {

    if (searchString !== null && searchString.length > 0) {
      this.filter.name = searchString;
    } else {
      this.filter.name = undefined;
    }

    this.students = [];

    this.fetchStudents();

  }

  onImportClicked() {
    this.router.navigate(['schooladmin', 'importStudents']);
  }

  onStudentClicked(student: StudentModel) {
    this.router.navigate(['schooladmin', 'students', student._id]);
  }


  onShowCreateStudent() {
    this.createStudentModalSv.onCreate(SchoolModel.getCurrent().culture, SchoolModel.getCurrent()._id).subscribe((s) => {
      if (s) {
        this.students = [];
        this.filter.skip = 0;
        this.fetchStudents();
      }
    });
  }

  onFilterChanged(filter: StudentFilterModel) {
    this.filter.skip = 0;
    this.filter = filter;
    this.students = [];
    this.fetchStudents();
  }

  onSortChanged(sort: EVOStudentListSort) {

    this.filter.updateWithListSort(sort);
    this.students = [];
    this.fetchStudents();

  }

}
