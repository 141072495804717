import {DropdownModel} from '../component/dropdown.model';

export class HelperModel {

  static saveAsImage(fileName, blobData) {

    const nav = (window.navigator as any);

    if (nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blobData, fileName);
    } else {
      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
    }

  }

  static convertBase64ToBlob(Base64Image: any) {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }

  /**
   * Solution gotten here: https://stackoverflow.com/questions/35939886/find-first-scrollable-parent
   * @param node
   */
  static isScrollabe(node: Element): boolean {

    if (node instanceof HTMLElement) {

      const style = getComputedStyle(node);

      return ['overflow', 'overflow-x', 'overflow-y'].some((propertyName) => {
        const value = style.getPropertyValue(propertyName);
        return value === 'auto' || value === 'scroll';
      });

    }

    return false;

  }

  static findScrollParent(element: Element): Element {

    let currentElement = element.parentElement;
    while (currentElement) {

      if (this.isScrollabe(currentElement)) {
        return currentElement;
      }

      currentElement = currentElement.parentElement;

    }

    return null;

  }

  static generateDropdownList(keys: string[]): DropdownModel[] {

    const list = [];

    for (const key of keys) {
      list.push(DropdownModel.GenerateFromKey(key));
    }

    return list;
  }

}
