import {Component, Input, OnInit} from '@angular/core';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {BarChartModel} from '../../../../../models/questions/subModels/barChart.model';

@Component({
  selector: 'app-evo-bar-chart',
  templateUrl: './evo-bar-chart.component.html',
  styleUrls: ['./evo-bar-chart.component.css']
})
export class EvoBarChartComponent implements OnInit {

  BarChartModel = BarChartModel;

  @Input() question: QuestionModel = new BarChartModel();

  constructor() { }

  ngOnInit() {
  }

  castedQuestion(): BarChartModel {
    return <BarChartModel>this.question;
  }

  setNumBarSeparators(separators: string) {
    this.castedQuestion().model.numBarSeparators = parseInt(separators, 10);

  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.barChart) {
        return true;
      }
    }

    return false;

  }

}
