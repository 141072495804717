import {Component, OnInit} from '@angular/core';
import {SchoolAdminTeacherService} from '../../../services/school/school-admin-teacher.service';
import {UserFilter, UserModel} from '../../../models/authentication/user.model';
import {Router} from '@angular/router';
import {EVOTeacherListSort} from '../../../shared/list-views/evo-teacher-list-view/evo-teacher-list-view.component';
import {SchoolAdminService} from '../../../services/school/school-admin.service';
import {SchoolModel} from '../../../models/school/school.model';
import {SchoolAdminLinkInvitationService} from '../../../services/school/school-admin-link-invitation.service';
import {CountryModel} from '../../../models/localization/country.model';
import {StringExtensionModel} from '../../../models/extensions/string-extension.model';
import {ModalService} from '../../../shared/modals/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorModel} from '../../../models/shared/error.model';
import {EVOListViewColumn} from '../../../models/UI/EVOListViewColumn.model';

@Component({
  selector: 'app-school-school-teachers',
  templateUrl: './school-admin-teachers.component.html',
  styleUrls: ['./school-admin-teachers.component.css']
})
export class SchoolAdminTeachersComponent implements OnInit {

  public teacherFilter = new UserFilter();
  public hasMoreData = true;
  public teachers: UserModel[] = [];

  public teachersToInvite: UserModel[] = [];
  public AccessTypes = UserModel.SchoolAccessTypesDropdown;

  showShareWindow = false;
  SchoolModel = SchoolModel;

  isCreatingNewLink = false;

  EVOListViewColumn = EVOListViewColumn;

  constructor(private teacherSv: SchoolAdminTeacherService,
              private schoolSv: SchoolAdminService,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private schoolLinkInvSv: SchoolAdminLinkInvitationService,
              private router: Router) {
  }

  ngOnInit() {
    this.teacherFilter.limit = 20;
    this.onFetchMoreTeachers();

    this.schoolSv.fetchSchool(SchoolModel.getCurrent()._id).subscribe((school) => {

      SchoolModel.setCurrent(school);

    });

  }

  searchFilteredTeachers(searchString: string) {

    this.teacherFilter.skip = 0;
    this.teacherFilter.name = searchString;
    this.teacherFilter.email = searchString;
    this.hasMoreData = true;
    this.teacherSv.fetchTeachers(this.teacherFilter).subscribe((filteredTeachers) => {

      this.hasMoreData = true;
      if (filteredTeachers.length === 0) {
        this.hasMoreData = false;
      }

      this.teachers.addUniqueArrayElements(filteredTeachers);

    });

  }

  onFetchMoreTeachers() {

    this.teacherFilter.skip = this.teachers.length;

    this.teacherSv.fetchTeachers(this.teacherFilter).subscribe((teachers) => {

      this.hasMoreData = teachers.length / this.teacherFilter.limit === 1;

      this.teachers.addUniqueArrayElements(teachers);

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  getLink(): string {

    return CountryModel.getTeacherInvitationLinkForCountry(SchoolModel.getCurrent().country, SchoolModel.getCurrent().linkInvitationId);

  }

  onLinkCopyClicked() {

    StringExtensionModel.copyStringToClipboard(this.getLink());
    this.modalSv.showAlertModal(this.tSv.instant('reusable.success'),
      this.tSv.instant('reusable.copySuccess')).subscribe();

  }

  onNewLinkClicked() {

    this.isCreatingNewLink = true;
    this.schoolLinkInvSv.generateNewLink(SchoolModel.getCurrent()._id).subscribe((school) => {
      SchoolModel.setCurrent(school);
      this.isCreatingNewLink = false;
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.successMessage'));
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onInvitationsClicked() {

    this.router.navigate(['schooladmin', 'invitations']);

  }

  onShareClicked() {

    this.showShareWindow = true;

  }

  onImportInvitationsClicked() {

    this.router.navigate(['schooladmin', 'importInvitations']);

  }

  onTeacherClicked(teacher: UserModel) {

    this.router.navigate(['schooladmin', 'teachers', teacher._id]);

  }

  onSortChanged(sort: EVOTeacherListSort) {

    this.teacherFilter.sort = sort.value;
    this.teachers = [];
    this.onFetchMoreTeachers();

  }

  onLinkInvitationEnableChanged(value: boolean) {

    SchoolModel.getCurrent().linkInvitationEnabled = value;

    if (!SchoolModel.getCurrent().linkInvitationId) {

      this.schoolLinkInvSv.generateNewLink(SchoolModel.getCurrent()._id).subscribe((school) => {

        SchoolModel.setCurrent(school);

        // tslint:disable-next-line:max-line-length
        this.schoolLinkInvSv.enableLinkInvitation(SchoolModel.getCurrent()._id, SchoolModel.getCurrent().linkInvitationEnabled).subscribe((s2) => {

          SchoolModel.setCurrent(s2);

        }, (err: ErrorModel) => {
          this.modalSv.showTranslatedErrorModal(err);
        });

      }, (err: ErrorModel) => {
        this.modalSv.showTranslatedErrorModal(err);
      });

    } else {

      // tslint:disable-next-line:max-line-length
      this.schoolLinkInvSv.enableLinkInvitation(SchoolModel.getCurrent()._id, SchoolModel.getCurrent().linkInvitationEnabled).subscribe((school) => {

        SchoolModel.setCurrent(school);

      }, (err: Error) => {
        this.modalSv.showTranslatedErrorModal(err);
      });
    }

  }

}
