import {Component, OnInit} from '@angular/core';
import {StudentModel} from '../../models/authentication/student.model';
import {Router} from '@angular/router';
import {AdminStudentService} from '../../services/admin/admin-student.service';
import {CountryModel} from '../../models/localization/country.model';
import {EVOStudentListSort} from '../../shared/list-views/evo-student-list-view/evo-student-list-view.component';
import {StudentFilterModel} from '../../models/filters/student-filter.model';


@Component({
  selector: 'app-school-students',
  templateUrl: './admin-students.component.html',
  styleUrls: ['./admin-students.component.css']
})
export class AdminStudentsComponent implements OnInit {

  students: StudentModel[] = [];
  studentFilter = new StudentFilterModel();
  hasMoreData = true;
  countryDropdownList = CountryModel.privateDropdownList(true);

  constructor(private adminStudentSv: AdminStudentService, private router: Router) {
  }

  ngOnInit() {
    this.countryDropdownList.splice(0, 0, {key: null, value: 'World', iconURL: './assets/school-admin/world.png'});
    this.studentFilter.limit = 20;
    this.fetchStudents();
  }

  fetchStudents() {

    this.studentFilter.skip = this.students.length;

    this.adminStudentSv.fetchStudents(this.studentFilter).subscribe((students) => {

      this.hasMoreData = this.studentFilter.limit === students.length;
      this.students.addUniqueArrayElements(students);

    });

  }

  searchFilteredStudents(searchString: string, country: string) {

    this.studentFilter.name = searchString;
    this.studentFilter.country = country;
    this.fetchStudents();

  }

  onStudentClicked(student: StudentModel) {
    this.router.navigate(['admin', 'students', student._id, 'edit']);
  }

  onSortClicked(sort: EVOStudentListSort) {

    this.studentFilter.updateWithListSort(sort);
    this.students = [];
    this.fetchStudents();

  }

}
