import { Component, OnInit } from '@angular/core';
import {EVOSegmentedControlItem} from '../../../shared/reusable-controls/evo-segmented-control/evo-segmented-control.component';
import {SchoolAdminService} from '../../../services/school/school-admin.service';
import {SchoolModel} from '../../../models/school/school.model';
import {ErrorModel} from '../../../models/shared/error.model';
import {ModalService} from '../../../shared/modals/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {HelperModel} from '../../../models/extensions/helper.model';

@Component({
  selector: 'app-school-admin-settings',
  templateUrl: './school-admin-settings.component.html',
  styleUrls: ['./school-admin-settings.component.css']
})
export class SchoolAdminSettingsComponent implements OnInit {

  pages = [
    new EVOSegmentedControlItem('general', 'schoolAdministration.settings.general.title')
  ];

  school: SchoolModel;
  culture: string;

  multiplicationSign: string;
  multiplicationSigns = SchoolModel.MultiplicationSign.dropdownModel();

  divisionSign: string;
  divisionSigns = SchoolModel.DivisionSign.dropdownModel();

  unitSystem: string;
  unitySystems = SchoolModel.UnitSystem.dropdownModel();

  constructor(private schoolSv: SchoolAdminService,
              private tSv: TranslateService,
              private modalSv: ModalService) { }

  ngOnInit() {

    this.schoolSv.fetchSchool(SchoolModel.getCurrent()._id).subscribe((s) => {
      this.school = s;
      this.culture = this.school.culture;
      this.multiplicationSign = this.school.localeSettings.multiplicationSign;
      this.divisionSign = this.school.localeSettings.divisionSign;
      this.unitSystem = this.school.localeSettings.unitSystem;
    });

  }

  canSave(): boolean {

    if (this.school && this.culture) {

      return this.culture !== this.school.culture;
    }

    return false;
  }

  save() {

    this.schoolSv.changeSchoolLanguage(this.culture).subscribe((school) => {
      SchoolModel.setCurrent(school);
      this.school = school;
      this.culture = school.culture;
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'),
        this.tSv.instant('reusable.successMessage'));
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  canSaveMultiplicationSign() {

    if (this.school &&
      this.multiplicationSign &&
      this.multiplicationSign !== this.school.localeSettings.multiplicationSign) {

      return true;

    }

    return false;

  }

  canSaveDivisionSign() {

    if (this.school &&
      this.divisionSign &&
      this.divisionSign !== this.school.localeSettings.divisionSign) {

      return true;
    }

    return false;

  }

  canSaveUnitSystem() {

    if (this.school &&
      this.unitSystem &&
      this.unitSystem !== this.school.localeSettings.unitSystem) {

      return true;
    }

    return false;

  }

  saveLocaleSettings() {

    this.schoolSv.changeLocaleSettings(this.multiplicationSign, this.divisionSign, this.unitSystem).subscribe((school) => {

      SchoolModel.setCurrent(school);
      this.school = school;

      this.multiplicationSign = this.school.localeSettings.multiplicationSign;
      this.divisionSign = this.school.localeSettings.divisionSign;
      this.unitSystem = this.school.localeSettings.unitSystem;


    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

}
