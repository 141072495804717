import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ChangeLangCountryService} from '../change-lang-country/change-lang-country.service';
import {UserModel} from '../../../models/authentication/user.model';
import {CountryModel} from '../../../models/localization/country.model';
import {CultureModel} from '../../../models/localization/culture.model';
import {TranslateService} from '@ngx-translate/core';
import {StringExtensionModel} from '../../../models/extensions/string-extension.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  gbInstagram = 'https://www.instagram.com/evolytes_uk/?hl=fr';
  frInstagram = 'https://www.instagram.com/evolytesfr/?hl=fr';
  iceInstagram = 'https://www.instagram.com/evolytesisland/?hl=fr';

  gbFacebook = 'https://www.facebook.com/evolytesUK';
  frFacebook = 'https://www.facebook.com/evolytesfr';
  iceFacebook = 'https://www.facebook.com/evolytesisland/';

  appStoreUs = 'https://apps.apple.com/us/app/evolytes/id1469773602';
  appStoreFr = 'https://apps.apple.com/fr/app/evolytes/id1469773602';

  youtube = 'https://www.youtube.com/channel/UC0Y5ZwrEk0-Dwtl2FJXqMqQ';

  linkedin = 'https://www.linkedin.com/company/evolytes/';

  @Output() countryChanged = new EventEmitter<string>();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private tSv: TranslateService,
              private changeLangCountrySv: ChangeLangCountryService) {
  }

  ngOnInit() {

  }

  onEvolytesClick() {
    this.router.navigate(StringExtensionModel.getHomeURLPaths(['landing']));
  }

  onGameClick() {
    this.router.navigate(StringExtensionModel.getHomeURLPaths(['features', 'game']));
  }

  onReasearchClick() {
    this.router.navigate(StringExtensionModel.getHomeURLPaths(['research']));
  }

  onSchoolsClicked() {
    this.router.navigate(StringExtensionModel.getHomeURLPaths(['schools']));
  }


  onAboutUsClick() {
    this.router.navigate(StringExtensionModel.getHomeURLPaths(['aboutUs']));
  }

  getCountryString(): string {

    if (UserModel.getCurrent() && UserModel.getCurrent().country) {

      return CountryModel.title(UserModel.getCurrent().country);
    }

    return CountryModel.title(CountryModel.getHomepageCountry());
  }

  getCountryFlagImage(): string {

    return CountryModel.iconURL(CountryModel.getHomepageCountry());
  }

  getCultureString(): string {

    return CultureModel.title(CultureModel.getHomepageCulture());
  }


  onCultureClick() {

    this.changeLangCountrySv.showLangCountrySelection().subscribe(payload => {

      CountryModel.setHomepageCountry(payload.country);

      const pastCulture = CultureModel.getHomepageCulture();
      CultureModel.setHomepageCulture(payload.culture);

      this.tSv.use(payload.culture);

      const paths = StringExtensionModel.pathsFromURL(this.router.url);
      const newPaths = StringExtensionModel.replacePathParameter(paths, pastCulture, payload.culture);

      CountryModel.onCountryOrCultureRedirection(this.router, newPaths, payload.country, payload.culture, this.tSv);

    });
  }

  onFaqClicked() {
    this.router.navigate(StringExtensionModel.getHomeURLPaths(['faq']));
  }

  onContactUsClick() {
    this.router.navigate(StringExtensionModel.getHomeURLPaths(['contactUs']));
  }

  goToTerms() {
    this.router.navigate(StringExtensionModel.getHomeURLPaths(['tc']));
  }

  goToPolicy() {
    this.router.navigate(StringExtensionModel.getHomeURLPaths(['privacy']));
  }

  goToInstagram() {
    switch (CultureModel.getHomepageCulture()) {
      case 'fr-FR' : return window.open(this.frInstagram);
      case 'is-IS' : return window.open(this.iceInstagram);
      default : return window.open(this.gbInstagram);
    }
  }

  goToFacebook() {
    switch (CultureModel.getHomepageCulture()) {
      case 'fr-FR' : return window.open(this.frFacebook);
      case 'is-IS' : return window.open(this.iceFacebook);
      default : return window.open(this.gbFacebook);
    }
  }

  goToLinkedin() {
    window.open(this.linkedin);
  }

  goToYoutube() {
    window.open(this.youtube);
  }

  goToAppStore() {
    switch (CultureModel.getHomepageCulture()) {
      case 'fr-FR' : return window.open(this.appStoreFr);
      default : return window.open(this.gbInstagram);
    }
  }
}
