import {Component, Input, OnInit} from '@angular/core';
import {CultureModel} from '../../../models/localization/culture.model';
import {DescriptionModel} from '../../../models/shared/description.model';

@Component({
  selector: 'app-evo-translated-list-input',
  templateUrl: './evo-translated-list-input.component.html',
  styleUrls: ['./evo-translated-list-input.component.css']
})
export class EvoTranslatedListInputComponent implements OnInit {

  cultures = CultureModel.list();
  @Input() value: DescriptionModel;

  constructor() { }

  ngOnInit() {
  }

}
