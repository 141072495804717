import { Injectable } from '@angular/core';
import {AuthService} from '../auth.service';
import {Observable} from 'rxjs';
import {QuestionModel} from '../../models/questions/question.model';
import {StudentModel} from '../../models/authentication/student.model';
import {map} from 'rxjs/operators';
import {generateQuestion, generateQuestionList} from '../../models/questions/question-generator';
import {StringExtensionModel} from '../../models/extensions/string-extension.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminQuestionService {

  constructor(private authSv: AuthService) { }

  createQuestion(q: QuestionModel): Observable<QuestionModel> {

    return this.authSv.post('/question-types', q).pipe(map((response) => {

      const question = generateQuestion(response.data);

      return question;

    }));

  }

  updateQuestion(q: QuestionModel): Observable<QuestionModel> {

    return this.authSv.patch('/question-types/' + q._id, q).pipe(map((response) => {

      const question = generateQuestion(response.data);

      return question;

    }));

  }

  archiveQuestion(qId: string, password: string): Observable<QuestionModel> {

    return this.authSv.post(`/question-types/${qId}/archive`, { password }).pipe(map((response) => {

      const q = generateQuestion(response.data);
      return q;

    }));

  }

  publishQuestion(qId: string, isPublished: boolean): Observable<QuestionModel> {

    return this.authSv.patch('/question-types/' + qId,
      { isPublished: isPublished }).pipe(map((response) => {

        const question = generateQuestion(response.data);

        return question;

    }));

  }

  deleteQuestion(qId: string): Observable<QuestionModel> {

    return this.authSv.delete('/question-types/' + qId).pipe(map((response) => {

      const question = generateQuestion(response.data);

      return question;

    }));

  }

  fetchQuestion(qId: string): Observable<QuestionModel> {

    return this.authSv.get('/question-types/' + qId).pipe(map((response) => {

      const question = generateQuestion(response.data);

      return question;

    }));

  }

  /**
   * Only to be used by administrators
   * @param filter the filter of the questions to fetch as they are country specific
   */
  fetchAdminQuestions(filter: EVOQuestionFilter = null): Observable<QuestionModel[]> {

    let url = environment.evoApi + '/question-types';
    if (filter) {
      url += StringExtensionModel.queryString(filter);
    }

    return this.authSv.get(url, null, true, true).pipe(map((response) => {

      const questions = generateQuestionList(response.data);

      return questions;

    }));

  }

  fetchQuestionCategories(): Observable<string[]> {

    return this.authSv.get('/question-types-categories').pipe(map((response) => {

      const categories = response.data;

      return categories;

    }));

  }

  fetchQuestionCategoryTree(): Observable<object> {

    return this.authSv.get('/question-types-tree').pipe(map((response) => {

      const tree = response.data;

      return tree;

    }));

  }

  fetchQuestionRepresentationTree(): Observable<object> {

    return this.authSv.get('/question-representation-tree').pipe(map((response) => {

      const representationTree = response.data;

      return representationTree;

    }));

  }

  fetchQuestionAnswerTypeTree(): Observable<object> {

    return this.authSv.get('/answer-type-tree').pipe(map((response) => {

      const answerTypeTree = response.data;

      return answerTypeTree;

    }));

  }

}

export class EVOQuestionFilter {

  name: string;

  // Comma separated list
  questionIds: string;
  excludeQuestionIds: string;

  // Filter by topic
  topic: string;

  // Filter by category
  category: string;

  // Filter by SubCategory
  subCategory: string;

  // Filter by representation
  representation: string;

  // Filter by answerType
  answerType: string;

  // Filter by publishing
  isPublished: boolean;

  // Filter by Starter Question
  isStarterQuestion: boolean;

  // Filter by archived
  isArchived: boolean;

  sortByName = 1;
  sortByCategory = 1;
  sortBySubcategory: number;

  skip = 0;
  limit = 2000;

}
